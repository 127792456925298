<template>
  <div class="TaoCan">
    <div class="top">
<!--      <el-button>全部产品</el-button>-->
      <!--      <el-button v-if="types=='exam'" @click="jcxm">检查收费</el-button>-->
    </div>
<!--    <div class="znfa">智能方案描述：-&#45;&#45;</div>-->
    <ul class="cpnr" style="font-size: 16px">
<!--      <li>品牌</li>-->
      <li>产品名</li>
<!--      <li>价格</li>-->
<!--      <li>库存</li>-->
      <li>操作</li>
    </ul>

    <div class="cp-box" ref="cpnrs">
      <ul  class="cpnr" style="font-size: 16px" v-for="(item, idx) in cpdata" @click="xz(idx)" >
        <li>{{item.CPPS_Name}}</li>
<!--        <li style="text-align: left">-->
<!--          {{item.ProductDetail.Product.ProductName}}-->
<!--          <span v-if="item.ProductDetail.AllProduct.ProductSortKey == 'lens'">-->
<!--            <span v-if="item.ProductDetail.AllProduct.DS || item.ProductDetail.AllProduct.DC">(-->
<!--              <span style="padding-left: 10px;" v-if="item.ProductDetail.AllProduct.DS">DS:{{item.ProductDetail.AllProduct.DS}}</span>-->
<!--              <span style="padding-left: 10px;" v-if="item.ProductDetail.AllProduct.DC">DC:{{item.ProductDetail.AllProduct.DC}}</span>)-->
<!--            </span>-->
<!--          </span>-->
<!--        </li>-->
        <!--        <li>{{item.ProductDetail.Product.ProductOfficalPrice}}</li>-->
<!--        <li>{{item.ShopProduct.SPShopPrice}}</li>-->
<!--        <li>{{item.ShopProduct.SPbNoCountSale ? '不限' : item.ShopProduct.SPAmount }}</li>-->
        <li class="last">
<!--          <p :class="types=='exam'?'nyj':''" style="text-align: center;padding-right: .2rem;" @click.stop="getcpxq(item.ProductDetail.Product.ProductId, idx)">详情</p>-->
          <p v-if="isz" :class="types=='exam'?'nyj':''" @click.stop="add(item.CPPS_Id)">加入我的选择</p>
        </li>
      </ul>
    </div>

  </div>
</template>

<script>
import {Loading} from "element-ui";

export default {
  name: "TaoCan",
  data() {
    return {
      cpdata: [],
      cpname: '',
      fy: 1,
      maxfy: '',
      ppList: [],
      sxList: [],
      ppxzList: new Map(),
      ppid: '',
      drawer: false,
      showxq: false,
      cpmc: '',
      xqimgList: [],
      xzxqObj: {
        Brand: {},
        Product: {},
        ListProperty: [{
          PropertyGroup: {}
        }]
      },
      types: 'lens',
      cpidx: 0,
      checkList: [],
      xzlist: [],
      ppName: new Map(),
      xzpplsit: [],
      xzppmap: new Map(),
      cspplist: [],
      cssxList: [],
      isz: true,
      isxyc: true
    }
  },

  // watch: {
  //   "$route.query": {
  //     deep: true,
  //     handler(n, o) {
  //       this.init()
  //     }
  //   }
  // },

  created() {

    this.init()
  },

  mounted() {
    const _this = this
    this.$refs.cpnrs.addEventListener('scroll', function () {
      if (_this.$refs.cpnrs.scrollTop + _this.$refs.cpnrs.offsetHeight > _this.$refs.cpnrs.scrollHeight-10) {
        if (!_this.isxyc) return
        _this.isxyc = false
        _this.getcpList(1)
      }
    })
  },

  methods: {
    init() {
      // console.log(this._getUrl('CPPS_GetList'))
      let loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.7)',
        text: '加载中...'
      });
      console.log(this.$store.state.bases.shop.ShopId)
      this._http.post(this._getUrl('CPPS_GetList'), {
        "token": {
          "TimeSpan": 1566827733,
          "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        "SearchCondition": {
          "CPPS_CompanyId": this.$store.state.users.user.Company.CompanyId,//填公司Id
          "CPPS_ShopId": this.$store.state.bases.shop.ShopId //填门店Id
        },
        "ReturnInfo": {
        },
      })
      .then(res => {
        loadingInstance.close()
        if (res.Result && res.Result.length) {
          this.cpdata = JSON.parse(JSON.stringify(res.Result))
        }
        // console.log(res)
      })
    },
    getcpList() {
      if (this.maxfy) {
        if (this.fy == this.maxfy) return this.$message('没有更多了');
      }else {
        this.fy = 1
      }
      this.drawer = false
      let loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.7)',
        text: '加载中...'
      });
      let jk = this.types == 'lens'?'Recommended Lens':'Recommended Frame'

      let str = '',arr = this.xzpplsit
      for (let i = 0; i < arr.length; i++) {
        str += this.sxList[arr[i][0]].PropertyGroup.PropertyGroupKey+':'
        for (let j = 0; j < arr[i][1].length; j++) {
          str += this.sxList[arr[i][0]].ListProperty[arr[i][1][j]].PropertyKey + ','
        }
        str += ';'
      }

      // console.log(`${jk}:${str}`)
      this._http.post(this._getUrl('priceSortList'), {
        SearchString: `${jk}:${str}`,
        "SearchCondition": {
          "ShopId": this.$store.state.bases.shop.ShopId,//实际ShopId
          "IndexMin": this.fy,
          "IndexMax":this.fy + 20,
          "SortKey": this.types,//镜片：lens；镜框：frame
          // "SortKey": 'exam',//镜片：lens；镜框：frame
          "ProductName": this.cpmc,
          "BrandId": this.ppid,//若有筛选品牌则填入该行，多个BrandId用英文逗号拼接
        },
        "ReturnInfo": {
          "Product": {
            "Brand": 1
          },
          "ShopProduct":1
        },
        "token": {
          "sToken": "E0B1486293C779EEE7CD24AF277703D7",
          "TimeSpan": "1574670974"
        }
      })
          .then(res => {
            // console.log(res)
            this.isxyc = true
            loadingInstance.close()
            if (res.GetRelationListResult) {
              this.maxfy =  res.RecordCount
              this.cpdata = this.cpdata.concat(res.GetRelationListResult)
              this.fy > res.RecordCount?this.fy = res.RecordCount:this.fy += 20
            }
          })
    },

    getpp() {
      // if (this.types == 'exam') return
      this._http.post(this._getUrl('frameSort'), {
        "SortKey" : this.types,
        ShopId: this.$store.state.bases.shop.ShopId,
        "token": {
          "TimeSpan":1516645838674,
          "sToken":"9C25283F0362F10BFAFE5B5397285019"
        }
      })
          .then(res => {
            if (res.GetPropertyGroupBrandResult.ListBrand&&res.GetPropertyGroupBrandResult.ListBrand.length) {
              this.ppList = res.GetPropertyGroupBrandResult.ListBrand
              this.cspplist = res.GetPropertyGroupBrandResult.ListBrand
            }
            // if (res.GetPropertyGroupBrandResult.ListPropertyGroupWithProperty.length) {
            //   this.sxList = res.GetPropertyGroupBrandResult.ListPropertyGroupWithProperty
            // }
          })
    },

    handleCommand(idx) {
      let arr = [...this.ppList]
      arr[idx].isxz?arr[idx].isxz='':arr[idx].isxz=1
      this.ppList = arr
      if (this.ppxzList.get(arr[idx].BrandId)) {
        this.ppxzList.delete(arr[idx].BrandId)
        this.ppName.delete(arr[idx].BrandId)
      }else {
        this.ppxzList.set(arr[idx].BrandId, arr[idx].BrandId)
        this.ppName.set(arr[idx].BrandId, arr[idx].BrandName)
      }
      this.ppid = Array.from(this.ppxzList.values()).join(',')
      // this.xzpplsit = Array.from(this.ppName.values()).join(',')
    },

    sscp() {
      if (this.types == 'exam') return
      this.fy = 1
      this.maxfy = ''
      this.cpdata = []
      this.getcpList()
    },

    getcpxq(id, idx) {
      this.cpidx = idx
      this.$router.push({path: '/Iframe', query: {id: id, type: 14}})

      /*this._http.post(this._getUrl('frameDetail'), {
        "SearchCondition": {
          "ProductId": id
        },
        "ReturnInfo": {
          "Brand":true,
          "Sort":true,
          "ListUploadFile":true,
          "ListProperty":true
        },
        "token": {
          "sToken": "E92F7B74AE4E7F08F8C7E94800CB5469",
          "TimeSpan": "1576463918"
        }
      })
      .then(res => {
        let arr = [],obj = res.GetFullResult.ListUploadFile
        if (res.GetFullResult.ListUploadFile && res.GetFullResult.ListUploadFile.length) {
          for (let i = 0; i < res.GetFullResult.ListUploadFile.length; i++) {
            arr.push(obj[i].FullURL)
          }
          this.xqimgList = arr
          this.showxq = true
          this.xzxqObj = JSON.parse(JSON.stringify(res.GetFullResult))
        }
      })*/
    },

    getimgurl(id) {
      // getProductDetailList
      this._http.post(this._getUrl('frameDetail'), {
      })
    },

    add(id) {
      // if (this.types == 'exam') return

      this.$confirm('请确认是否将该套餐里的商品加入结算中心，请勿重复添加!', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.addTc(id)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },

    addTc(id) {
      let loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.7)',
        text: '添加中...'
      });
      this._http.post(this._getUrl('CPPS_IntoChooseProduct'), {
        "token": {
          "TimeSpan": 1566827733,
          "sToken": "768DCAFFF869CD61372790C2738012EC"
        },
        "CPPS_Id": id,//填这一行的套餐Id
        "ChooseId": this.$store.state.physicianVisits.xzConsulting,//填门店Id
        "ExpertId": this.$store.state.users.employees.ExpertId//填ExpertId
      })
          .then(res => {
            loadingInstance.close()
            this.$alert(res.displaymsg)
          })
    },

    xz(idx) {
      if (this.types != 'exam') return false
      if (this.checkList.indexOf(idx) == -1) {
        this.checkList.push(idx)
      }else {
        this.checkList.splice(this.checkList.indexOf(idx), 1)
      }
    },

    jcxm() {
      if (this.checkList == 0) return this.$alert('请选择商品！')
      let str = ''
      for (let i = 0; i < this.checkList.length; i++) {
        str += this.cpdata[this.checkList[i]].ProductDetail.Product.ProductId+','
      }
      this._http.post(this._getUrl('CustomerOrder_DirectInsert'), {
        "token" : {"sToken" : "54890E9E4162681879B02AF7B490628E",
          "TimeSpan" : "1642649562"
        },
        "UserId" : this.$store.state.users.user.UserId,
        "ShopId" : this.$store.state.bases.shop.ShopId,
        "ChooseProductId" : "",
        "CompanyId" : this.$store.state.users.user.Company.CompanyId,
        "CAPId" : "",
        "ChooseId" : this.$store.state.physicianVisits.xzConsulting,
        "ProductId" : str,
        "CustomerId" : this.$store.state.users.CsUser.CustomerId,
        "ExpertId" : this.$store.state.users.employees.ExpertId
      })
          .then(res => {
            // console.log(res)

          })
    },

    getAllcpSx() {
      this._http.post(this._getUrl('CCP_GetPropertyGroupBrand'), {
        "token": {
          "sToken": "301F39D31879DAB4F4416D31B4801C27",
          "TimeSpan": "1630915754"
        },
        "ShopId": this.$store.state.bases.shop.ShopId,
        "SortKey":this.types,//镜框frame，镜片lens
        "bAll":"0",
        "bSearch":true
      })
          .then(res => {
            if (res.GetPropertyGroupBrandResult.ListPropertyGroupWithProperty.length) {
              this.sxList = JSON.parse(JSON.stringify(res.GetPropertyGroupBrandResult.ListPropertyGroupWithProperty))
              this.cssxList = JSON.parse(JSON.stringify(res.GetPropertyGroupBrandResult.ListPropertyGroupWithProperty))
            }
          })
    },

    getsx(idx, idxb) {
      let arr = []
      let obj = JSON.parse(JSON.stringify(this.sxList))
      if (this.xzppmap.get(idx)) {
        if (this.xzppmap.get(idx).get(idxb) != undefined) {
          this.xzppmap.get(idx).delete(idxb)
          obj[idx].ListProperty[idxb].isxz = false
        }else {
          this.xzppmap.get(idx).set(idxb, idxb)
        }
      }else {
        this.xzppmap.set(idx, new Map().set(idxb, idxb))
      }

      let arrmap = Array.from(this.xzppmap.keys())
      for (let i = 0; i < arrmap.length; i++) {
        arr.push([arrmap[i], Array.from(this.xzppmap.get(arrmap[i]).values())])
      }
      for (let i = 0; i < arr.length; i++) {
        for (let j = 0; j < arr[i][1].length; j++) {
          obj[arr[i][0]].ListProperty[arr[i][1][j]].isxz = true
        }
      }
      this.xzpplsit = arr
      this.sxList = obj
    },

    tjs() {
      // if (this.types == 'exam') return
      this.fy = 1
      this.maxfy = ''
      this.cpdata = []
      this.getcpList()
    },

    cz() {
      this.ppid = ''
      this.xzpplsit = []
      this.ppName = new Map()
      this.xzppmap = new Map()
      this.ppxzList = new Map()
    },

    cshTime(keys) {
      // console.log(this.$store.state.Time.timeObj[keys].EXIT)
      this.$store.commit('Time/upTimeObj', {
        keys: keys,
        val: {
          ENTER: (new Date()).valueOf(),
          EXIT: this.$store.state.Time.timeObj[keys].EXIT
        }
      })
    },
    jsTime(keys,types=0) {
      if (types) {
        this.$store.commit('Time/upTimeObj', {
          keys: keys,
          val: {
            ENTER: 0,
            EXIT: 0
          }
        })
        return
      }
      if (this.$store.state.Time.timeObj[keys].ENTER !== 0) {
        this.$store.commit('Time/upTimeObj', {
          keys: keys,
          val: {
            ENTER: (new Date()).valueOf(),
            EXIT: ((new Date()).valueOf() - this.$store.state.Time.timeObj[keys].ENTER)/1000 + Number(this.$store.state.Time.timeObj[keys].EXIT)
          }
        })
      }
    }
  },
  beforeDestroy() {
    // if (this.types == 'lens') {
    //   if (this.$store.state.Time.timeObj.product_addToCart.ENTER != 0) {
    //     this.jsTime('product_addToCart')
    //   }
    // }else {
    //   if (this.$store.state.Time.timeObj.product_addToCart_frame.ENTER != 0) {
    //     this.jsTime('product_addToCart_frame')
    //   }
    // }
  }
}
</script>

<style scoped lang="scss">
.TaoCan {
  width: 100vw;
  height: 84vh;
  overflow: hidden;
  overflow-y: auto;
  padding-top: 1vh;
}
.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 7vw;
  height: 40px;
}
.znfa {
  padding: 10px 7vw;
  text-align: left;
}
.cpnr {
  display: flex;
  justify-content: center;
  align-items: center;
  li {
    padding: 10px;
    cursor: pointer;
    position: relative;
  }
  //li:nth-child(1) {
  //  width: 49%;
  //}
  li {width: 49%;}
  //li:nth-child(2) {width: calc(40% - 30px);}
  //li:nth-child(3) {width: calc(20% - 40px);}
  //li:nth-child(4) {width: 50px}
  //li:nth-child(5) {width: calc(20% - 30px);}
  .last {
    display: flex;
    justify-content: center;
    color: #40a7f6;
  }
}
.cp-box {
  width: 100%;
  height: calc(84vh - 140px);
  overflow: hidden;
  overflow-y: auto;
  ul {
    border-bottom: 1px solid #000000;
    &:hover {background: #cdcbcb;}
  }
  .xz {background: #cdcbcb;}
}

.sxbox {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: relative;
  display: flex;
  .cpmc {
    display: flex;
    align-items: center;
    justify-content: center;
    .cpmc-sr {
      width: 20vw;
    }
  }
  .left {
    width: 25%;
    p {padding: 10px;}
  }
  .rights {
    width: 75%;
    height: 90%;
    background: #FFFFFF;
    overflow-y: auto;
    margin-top: 20px;
    .rights-nr {
      padding: 10px 2vw;
      p {
        font-size: 16px;
        text-align: left;
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 0 25px;
        //border-bottom: 1px solid #5a5959;
        padding-bottom: 20px;
        li {
          width: 30%;
          background: #d2d7de;
          height: 35px;
          line-height: 35px;
          border-radius: 5px;
          cursor: pointer;
          margin-left: 2%;
          margin-top: 10px;
          &:hover {
            width: calc(30% - 2px) !important;
            height: 33px;
            line-height: 33px;
            background: #FFFFFF;
            color: #40a7f6;
            border: 1px solid #40a7f6;
          }
        }

      }
    }
  }
  .xz {
    width: calc(30% - 2px) !important;
    height: 33px;
    line-height: 33px;
    background: #FFFFFF !important;
    color: #40a7f6;
    border: 1px solid #40a7f6;
  }
}

.xqbox {
  width: 100%;
  height: 60vh;
  overflow-y: auto;
  img {
    width: 100%;
  }
}
.ckxq {
  width: 60vw;
  background: #b6b4b4 !important;
  color: #FFFFFF;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  .leftps {
    width: 25vw;
  }
  .rightss {
    width: 25vw;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    li {
      width: 48%;
    }
  }
}

.nyj {color: #8f8e8e}

.db {
  width: 100%;
  position: absolute;
  bottom: 1vh;
  display: flex;
  justify-content: center;
}

.cpnames {
  //width: 100%;
  padding: 10px 2vw;
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 16px;
  .cpmsr {
    width: 250px;
  }
}
</style>
